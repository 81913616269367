/* eslint
    prettier/prettier: "off",
    object-shorthand: "off",
    func-names: "off",
    no-unused-vars: "off"
*/
(function($) {
  Drupal.behaviors.LogoWidth = {
    attach: function(context, settings) {
      // Lets make logo appear in correct dimensions
      $(".logo.affiliate-logo").once("reformat-aff-mobile-name", function() {
        var imageWidth = $(
          ".affiliate-template .header-webform .logo.affiliate-logo img",
          context
        ).attr("width");
        var affLogoWidth = imageWidth / 2;
        $(".affiliate-template .logo.affiliate-logo").width(affLogoWidth);
      });
    }
  };
})(jQuery);
